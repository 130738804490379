import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's trivial to install the `}<inlineCode parentName="p">{`step`}</inlineCode>{` binary on your local machine.`}</p>
    <Alert severity="info" mdxType="Alert">
    <AlertTitle mdxType="AlertTitle">
        Building from source?
    </AlertTitle>
    <div>
        Check out our guide to <Link href="https://github.com/smallstep/cli/blob/master/docs/local-development.md" mdxType="Link">getting
        started with development</Link>.
  </div>
    </Alert>
    <h4>{`Select your operating system or infrastructure:`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#macos"
        }}>{`macOS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux-packages-amd64"
        }}>{`Linux Packages`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#debian"
            }}>{`Debian`}</a>{`   `}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#arch-linux"
            }}>{`Arch Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#redhat"
            }}>{`RedHat`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alpine-linux"
            }}>{`Alpine Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nixos"
            }}>{`NixOS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#freebsd"
            }}>{`FreeBSD`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux-binaries"
        }}>{`Linux binaries`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#kubernetes"
        }}>{`Kubernetes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#docker"
        }}>{`Docker`}</a></li>
    </ul>
    <h3>{`macOS`}</h3>
    <p>{`Install `}<inlineCode parentName="p">{`step`}</inlineCode>{` via `}<a parentName="p" {...{
        "href": "https://brew.sh/"
      }}>{`Homebrew`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install step
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`brew uninstall step`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <h3>{`Linux Packages (amd64)`}</h3>
    <h4>{`Debian Linux`}</h4>
    <p>{`Download and install the Debian package from our `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/releases/latest"
      }}>{`latest release`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`wget https://dl.step.sm/gh-release/cli/docs-cli-install/v0.23.1/step-cli_0.23.1_amd64.deb
sudo dpkg -i step-cli_0.23.1_amd64.deb
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`sudo dpkg -r step-cli`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <h4>{`Arch Linux`}</h4>
    <p>{`There is a community-maintained `}<a parentName="p" {...{
        "href": "https://archlinux.org/packages/community/x86_64/step-cli/"
      }}><inlineCode parentName="a">{`step-cli`}</inlineCode>{` package`}</a>{`.`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://www.archlinux.org/pacman/"
      }}>{`pacman`}</a>{` to install `}<inlineCode parentName="p">{`step`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pacman -S step-cli
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`pacman -R step-cli`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <p>{`Big shout out to the maintainers of these packages! We appreciate you.`}</p>
    <h4>{`RedHat`}</h4>
    <p>{`Download and install the RPM package from our `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/releases/latest"
      }}>{`latest release`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`wget https://dl.step.sm/gh-release/cli/docs-ca-install/v0.23.1/step-cli_0.23.1_amd64.rpm
sudo rpm -i step-cli_0.23.1_amd64.rpm
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`sudo dnf remove step-cli`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <h4>{`Alpine Linux`}</h4>
    <Alert severity="info" mdxType="Alert">
    <AlertTitle mdxType="AlertTitle">
        This package is in a community repository.
    </AlertTitle>
    <div>
      To install it, you'll need to append <code>http://dl-cdn.alpinelinux.org/alpine/edge/community</code> to <code>/etc/apk/repositories</code>.
      Alternatively, see <a href="#linux-other">Linux (other)</a>.
    </div>
    </Alert>
    <p>{`There is a community-maintained `}<a parentName="p" {...{
        "href": "https://pkgs.alpinelinux.org/package/edge/community/x86/step-cli"
      }}><inlineCode parentName="a">{`step-cli`}</inlineCode>{` package`}</a>{` Alpine Linux Package.`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://wiki.alpinelinux.org/wiki/Alpine_Linux_package_management"
      }}>{`apk`}</a>{` to install `}<inlineCode parentName="p">{`step`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`apk add step-cli
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`apk del step-cli`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <p>{`Big shout out to the maintainers of these packages! We appreciate you.`}</p>
    <h4>{`NixOS`}</h4>
    <p>{`Install the `}<a parentName="p" {...{
        "href": "https://search.nixos.org/packages?channel=20.09&show=step-cli&from=0&size=50&sort=relevance&query=step-cli"
      }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a>{` package on NixOS.`}</p>
    <h4>{`FreeBSD`}</h4>
    <p>{`Install the `}<a parentName="p" {...{
        "href": "https://www.freshports.org/security/step-cli/"
      }}><inlineCode parentName="a">{`step-cli`}</inlineCode></a>{` package on FreeBSD.`}</p>
    <h3>{`Linux binaries`}</h3>
    <p>{`We have 386, amd64, arm64, armv7, mips, and mips64 releases available to download from our `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/releases/latest"
      }}>{`latest
release`}</a>{`.`}</p>
    <p>{`Place the binary in `}<inlineCode parentName="p">{`/usr/bin`}</inlineCode>{` for compatibility with the rest of our documentation.`}</p>
    <h3>{`Windows`}</h3>
    <h4>{`Install via Scoop`}</h4>
    <p>{`Install `}<inlineCode parentName="p">{`step`}</inlineCode>{` via `}<a parentName="p" {...{
        "href": "https://scoop.sh/"
      }}>{`scoop`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`scoop bucket add smallstep https://github.com/smallstep/scoop-bucket.git
scoop install smallstep/step
`}</code></pre>
    <p>{`To uninstall, run `}<inlineCode parentName="p">{`scoop remove step`}</inlineCode>{` and remove the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` configuration directory.`}</p>
    <h4>{`Install manually`}</h4>
    <p>{`Download the Windows binary from our `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/releases/latest"
      }}>{`latest releases`}</a>{` page and install it using PowerShell.`}</p>
    <p>{`Open PowerShell and run the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl.exe -LO https://dl.step.sm/gh-release/cli/docs-cli-install/v0.23.1/step_windows_0.23.1_amd64.zip
Expand-Archive -LiteralPath .\\step_windows_0.23.1_amd64.zip -DestinationPath .
step_0.23.1\\bin\\step.exe version
`}</code></pre>
    <p>{`Finally, move the `}<inlineCode parentName="p">{`step.exe`}</inlineCode>{` binary wherever you'd like it to into a location in your user's `}<inlineCode parentName="p">{`PATH`}</inlineCode>{`.`}</p>
    <h3>{`Testing your installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect https://smallstep.com
Certificate:
    Data:
        Version: 3 (0x2)
        Serial Number: 326381749415081530968054238478851085504954 (0x3bf265673332db2d0c70e48a163fb7d11ba)
    Signature Algorithm: SHA256-RSA
        Issuer: C=US,O=Let's Encrypt,CN=Let's Encrypt Authority X3
        Validity
            Not Before: Feb 8 13:07:44 2019 UTC
            Not After : May 9 13:07:44 2019 UTC
        Subject: CN=smallstep.com
[...]
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      